/** @jsx jsx */
import * as React from "react"
import { jsx, useColorMode, Box, Flex, Grid } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ColorModeToggle from "./colorModeToggle"

const Layout = ({ location, title, children, wrapper }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const menuLinks = data.site.siteMetadata?.menuLinks

  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = e => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <Box
        sx={{
          display: [`inline-block`, `flex`],
          justifyContent: [`left`, `space-between`],
        }}
      >
        <Box>
          <Link className="header-link-home" to="/">
            <StaticImage
              className="logo"
              src="../images/codingdespair-logo.svg"
              width={130}
              height={80}
              quality={95}
              alt="Lian logo"
            />
          </Link>
        </Box>
        <Box>
          <Box>
            <nav>
              <ul
                sx={{ ml: `0`, display: ["inline", "flex"], flex: [null, 1] }}
              >
                {menuLinks.map(link => (
                  <li
                    key={link.name}
                    style={{
                      listStyleType: `none`,
                      padding: `1rem`,
                    }}
                  >
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                ))}
                <li
                  key="toggle"
                  style={{
                    listStyleType: `none`,
                    padding: `1rem`,
                    margin: `auto`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: `center`,
                      justifyContent: `center`,
                      ml: `auto`,
                    }}
                  >
                    <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
                  </Box>
                </li>
              </ul>
            </nav>
          </Box>
        </Box>
      </Box>
    )
  } else {
    header = (
      <Box
        sx={{
          display: [`inline-block`, `flex`],
          justifyContent: [`left`, `space-between`],
        }}
      >
        <Box>
          <Link className="header-link-home" to="/">
            <StaticImage
              className="logo"
              src="../images/codingdespair-logo.svg"
              width={130}
              height={80}
              quality={95}
              alt="Coding Despair Logo"
            />
          </Link>
        </Box>
        <Box>
          <Box>
            <nav>
              <ul
                sx={{ ml: `0`, display: ["inline", "flex"], flex: [null, 1] }}
              >
                {menuLinks.map(link => (
                  <li
                    key={link.name}
                    style={{
                      listStyleType: `none`,
                      padding: `1rem`,
                    }}
                  >
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                ))}
                <li
                  key="toggle"
                  style={{
                    listStyleType: `none`,
                    padding: `1rem`,
                    margin: `auto`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: `center`,
                      justifyContent: `center`,
                      ml: `auto`,
                    }}
                  >
                    <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
                  </Box>
                </li>
              </ul>
            </nav>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box variant="wrapper.global">
      <header>{header}</header>
      {wrapper == "home" ? (
        <Box variant="wrapper.home">
          <main>{children}</main>
        </Box>
      ) : (
        <main>{children}</main>
      )}
      <footer>© {new Date().getFullYear()}. All rights reserved.</footer>
    </Box>
  )
}

export default Layout
